// function to add one full day after the selected day - takes date as string and returns the same
const incrementDate = (inputDate, increment) => {
  let dateFormatToTime = new Date(inputDate);
  let increasedDate = new Date(dateFormatToTime.getTime() + (increment*86400000));
  return increasedDate.getFullYear().toString() +'-'+(increasedDate.getMonth()+1).toString().padStart(2, "0")+'-'+increasedDate.getDate().toString().padStart(2, "0");
}

const decrementDate = (inputDate, decrement) => {
  let dateFormatToTime = new Date(inputDate);
  let decreasedDate = new Date(dateFormatToTime.getTime() - (decrement*86400000));
  return decreasedDate.getFullYear().toString() +'-'+(decreasedDate.getMonth()+1).toString().padStart(2, "0")+'-'+decreasedDate.getDate().toString().padStart(2, "0");
}

// setting starting date as 02-03-2022
const StartingDateCollectingData = "2022-03-02";

// calculate default ending date as 1 day after today's date
const today = new Date();
let todayDateToString = today.getFullYear().toString() +'-'+(today.getMonth()+1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");
// let defaultEndingDate = incrementDate(todayDateToString, 1);
// let defaultEndingDateToShowOnLoad = today.getFullYear().toString() +'-'+(today.getMonth()+1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

// const defaultStartingDate = decrementDate(todayDateToString, 3);

// setting up default starting and ending date manually to solve problem with twitter streaming api access
let manualStartingDate = "2023-03-01";
let manualEndingDate = "2023-03-07";
const defaultStartingDate = manualStartingDate;
const defaultEndingDate = manualEndingDate;
const defaultEndingDateToShowOnLoad = manualEndingDate;

//rounding function
const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
}

export {StartingDateCollectingData, defaultStartingDate, defaultEndingDate, defaultEndingDateToShowOnLoad, incrementDate, decrementDate, roundToTwo}; 
