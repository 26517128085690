import React, { useState } from "react";

import {
  Col,
  // Button,
} from "reactstrap";
import { TwitterTweetEmbed } from "react-twitter-embed";
import Masonry from 'react-masonry-css'

import MiniLoader from "../components/UI/MiniLoader";

const breakpointColumnsObj = {
  default: 4,
  1635: 3,
  1285: 2,
  650: 1
};

const FavTweets = ({data}) => {
  // const sortedFavTweets = data.favorites.sort((a,b) => (Object.values(a)[0] > Object.values(b)[0]) ? -1 : 1);
  const [loading, setLoading] = useState(true);
  // const [mostFavTweets, setMostFavTweets] = useState(sortedFavTweets.slice(0,10));
  let mostFavTweets = data.favorites.sort((a,b) => (Object.values(a)[0] > Object.values(b)[0]) ? -1 : 1).slice(0,10);

  const FavTweetsLoaded = () => {
    setLoading(false);
    return;
  } 

  // let loadMoreCounter = 0;
  // const loadMore = () => {
  //   loadMoreCounter = loadMoreCounter + 1;
  //   setMostFavTweets(sortedFavTweets.slice(0, loadMoreCounter * 10));
  // }

  // console.log(mostFavTweets);

  return (
    <>
    {loading && (
      <Col className="px-3 py-3">
          <MiniLoader />
      </Col>
    )}
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_col"
    >
      {mostFavTweets.map(item => (
        <div key={Object.keys(item)[0]}>
          <TwitterTweetEmbed
            onLoad={FavTweetsLoaded}
            tweetId={Object.keys(item)[0]}
          />
        </div>
      ))
      }
    </Masonry>
    {/* {!loading && <Button onClick={loadMore}>Load more</Button>} */}
    </>
  )
}

const FavTweetsRecent = ({data}) => {
  const [loading, setLoading] = useState(true);
  let mostFavTweetsRecent = data.favorites.slice(0,10);

  const FavTweetsLoaded = () => {
    setLoading(false);
    return;
  } 

  // console.log(mostReTweets);

  return (
    <>
    {loading && (
      <Col className="px-3 py-3">
          <MiniLoader />
      </Col>
    )}
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_col"
    >
      {mostFavTweetsRecent.map(item => (
        <div key={Object.keys(item)[0]}>
          <TwitterTweetEmbed
            className="test"
            onLoad={FavTweetsLoaded}
            tweetId={Object.keys(item)[0]}
          />
        </div>
      ))
      }
    </Masonry>
    </>
  )
}

const ReTweets = ({data}) => {
  const [loading, setLoading] = useState(true);
  let mostReTweets = data.retweets.sort((a,b) => (Object.values(a)[0] > Object.values(b)[0]) ? -1 : 1).slice(0,10);

  const ReTweetsLoaded = () => {
    setLoading(false);
    return;
  } 

  // console.log(mostReTweets);

  return (
    <>
    {loading && (
      <Col className="px-3 py-3">
          <MiniLoader />
      </Col>
    )}
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_col"
    >
      {mostReTweets.map(item => (
        <div key={Object.keys(item)[0]}>
          <TwitterTweetEmbed
            className="test"
            onLoad={ReTweetsLoaded}
            tweetId={Object.keys(item)[0]}
          />
        </div>
      ))
      }
    </Masonry>
    </>
  )
}

const ReTweetsRecent = ({data}) => {
  const [loading, setLoading] = useState(true);
  let mostReTweetsRecent = data.retweets.slice(0,10);

  const ReTweetsLoaded = () => {
    setLoading(false);
    return;
  } 

  // console.log(mostReTweets);

  return (
    <>
    {loading && (
      <Col className="px-3 py-3">
          <MiniLoader />
      </Col>
    )}
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_col"
    >
      {mostReTweetsRecent.map(item => (
        <div key={Object.keys(item)[0]}>
          <TwitterTweetEmbed
            className="test"
            onLoad={ReTweetsLoaded}
            tweetId={Object.keys(item)[0]}
          />
        </div>
      ))
      }
    </Masonry>
    </>
  )
}


export { FavTweets, ReTweets, FavTweetsRecent, ReTweetsRecent };