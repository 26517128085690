/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Home from "views/Home";
import About from "views/About";
import Dashboard from "views/Dashboard";
import Wordclouds from "views/Wordclouds";
import TopFavorites from "views/TopFavorites";
import TopRetweeted from "views/TopRetweeted";
import TopUrls from "views/TopUrls";
import TopUsers from "views/TopUsers";
// import TopicModeling from "views/TopicModeling";

var routes = [
  // {
  //   path: "/home",
  //   name: "Home",
  //   icon: "nc-icon nc-app",
  //   component: Home,
  //   layout: "/admin",
  // },
  {
    path: "/home",
    name: "Dashboard",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/wordclouds",
    name: "Wordclouds",
    icon: "fas fa-comment-dots",
    component: Wordclouds,
    layout: "/admin",
  },
  {
    path: "/top-favorites",
    name: "Top Favorites",
    icon: "fas fa-heart",
    component: TopFavorites,
    layout: "/admin",
  },
  {
    path: "/top-retweeted",
    name: "Top Retweeted",
    icon: "fas fa-retweet",
    component: TopRetweeted,
    layout: "/admin",
  },
  {
    path: "/top-urls",
    name: "Top URLs",
    icon: "fa-solid fa-ranking-star",
    component: TopUrls,
    layout: "/admin",
  },
  {
    path: "/top-users",
    name: "Top Users",
    icon: "fa-solid fa-people-group",
    component: TopUsers,
    layout: "/admin",
  },
  // {
  //   path: "/topic-modeling",
  //   name: "Topic Modeling",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: TopicModeling,
  //   layout: "/admin",
  // },
  {
    path: "/about",
    name: "About",
    icon: "nc-icon nc-alert-circle-i",
    component: About,
    layout: "/admin",
  },
];
export default routes;
