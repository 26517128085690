import React, { useState } from "react";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";

import UsersComp from "../components/dateRenderedComp/UsersComp";
import {StartingDateCollectingData, defaultStartingDate, defaultEndingDate, defaultEndingDateToShowOnLoad, incrementDate} from "../variables/variables";

const TopUsers = () => {
  const [startDate, setStartDate] = useState(defaultStartingDate);
  const [endDate, setEndDate] = useState(defaultEndingDate);

  const handleStartDateSelection = (e) => {
    e.preventDefault();
    setStartDate(e.target.value);
  }

  const handleEndDateSelection = (e) => {
    e.preventDefault();
    setEndDate(incrementDate(e.target.value,1));
  }

  // calculate starting - ending dates in date format and 17-03-22 as a date to compare to
  const checkstart = new Date(startDate + "T00:00:00");
  const checkend = new Date(endDate + "T00:00:00");
  const limit = new Date("2022-03-19T00:00:00");

  return (
    <>
      <div className="content">
        <Row className="py-1">
          <Col>
            <Card>
              <CardBody>
                <p>Select dates to filter data and results only from tweets within this period
                   (data collection period started on 2 March 2022).</p>
                <Form>
                  <Row form>
                    <Col xs={4}>
                      <FormGroup inline>
                        <Label for="selectStartDate">
                          <b>From:</b>
                        </Label>
                        <Input
                          id="selectStartDate"
                          name="start-date"
                          placeholder="date placeholder"
                          type="date"
                          min={StartingDateCollectingData}
                          max={defaultEndingDateToShowOnLoad}
                          onChange={handleStartDateSelection}
                          defaultValue={defaultStartingDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup inline>
                        <Label for="selectEndDate">
                          <b>To:</b>
                        </Label>
                        <Input
                          id="selectEndDate"
                          name="end-date"
                          placeholder="date placeholder"
                          type="date"
                          min={StartingDateCollectingData}
                          max={defaultEndingDateToShowOnLoad}
                          onChange={handleEndDateSelection}
                          defaultValue={defaultEndingDateToShowOnLoad}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UsersComp startDate={startDate} endDate={endDate} checkstart={checkstart} checkend={checkend} limit={limit} />
      </div>
    </>
  )
}

export default TopUsers;