import React from "react";
import ReactWordCloud from "react-wordcloud";
import { select } from "d3-selection";

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';


const options = {
  colors: ["#2678BF", "#1D4B73", "#D9B504", "#8C7503"],
  enableTooltip: true,
  deterministic: false,
  fontFamily: "anton",
  fontSizes: [15, 75],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1.5,
  rotations: 1,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

// callback to open twiter webpage with the results of hashtag search on word click
function getCallback(callback) {
  return function (word, event) {
    const isActive = callback !== "onWordMouseOut";
    const element = event.target;
    const text = select(element);
    text
      .on("click", () => {  
        if (isActive) {
          window.open(`https://twitter.com/search?q=%23${word.text}&src=typed_query`, "_blank");
        }
      })
  };
}

const callbacks = {
  onWordClick: getCallback("onWordClick"),
  onWordMouseOut: getCallback("onWordMouseOut"),
  onWordMouseOver: getCallback("onWordMouseOver")
}

const WordCloud = ({data}) => {
  // create a blank array of words
  let words = [];
  // create an array of objects as input for react wordcloud
  (data.words).map(el => {
    let word = {
      'text': Object.keys(el),
      'value': Object.values(el), 
    }

    words = [...words, word ];
    return words;
  });

  // sort the array of objects and return only the 300 words with the biggest frequency
  // let sortedWords = words.sort((a,b) => (a.value > b.value) ? -1 : 1).slice(0,300);

  return (
    <>
    <div>
      <ReactWordCloud 
        maxWords={100}
        options={options}
        words={words} 
      />
    </div>
    </>
  )
}

const TagCloud = ({data}) => {
  let tags = [];

  (data.tags).map(el => {
    let tag = {
      'text': Object.keys(el),
      'value': Object.values(el), 
    }
    
    tags = [...tags, tag];
    return tags;
  });

  // sort the array of objects and return only the 300 tags with the biggest frequency
  // let sortedTags = tags.sort((a,b) => (a.value > b.value) ? -1 : 1).slice(0,300);


  return (
    <>
    <div>
      <ReactWordCloud 
        callbacks={callbacks}
        maxWords={100}
        options={options}
        words={tags} 
      />
    </div>
    </>
  )
}

export  { WordCloud, TagCloud };
