import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";


import { WordCloud, TagCloud } from "../../variables/wordclouds";
import Loader from "../UI/Loader";

const appHost = process.env.REACT_APP_HOST;


const Clouds = ({startDate, endDate}) => {
  const [loading, setLoading] = useState(true);
  const [dataWords, setDataWords] = useState([]);
  const [dataTags, setDataTags] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const FETCH_URL = `${appHost}/get_specific_results/words/${startDate}/${endDate}/`;
      const FETCH_URL2 = `${appHost}/get_specific_results/tags/${startDate}/${endDate}/`;
      try {
        const {data: response} = await axios.get(FETCH_URL);
        const {data: response2} = await axios.get(FETCH_URL2);
        setDataWords(response);
        setDataTags(response2);
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    }
    
    fetchData();

  }, [startDate, endDate]);


  return (
    <>
    {loading && ReactDOM.createPortal(
      <Loader />
      ,document.getElementById("overlays"))}
      {!loading && (
      <div>
      <Row className="py-3" id="hashtagcloud">
        <Col>
          <Card>
            <CardBody>
              <h3 className="text-warning"><i className="fas fa-hashtag text-warning" /> Most popular hashtags</h3>
              <p className="info-text"><i className="fas fa-info-circle" /> The graph presents the hashtags used by Twitter 
                users  in their tweets about the war. The larger the size of a hashtag, the most
                 frequently it appears in the tweets. Hovering over a hashtag shows the number 
                 of times this hashtag appeared in our data. </p>
              <TagCloud data={dataTags} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="py-3" id="wordcloud">
        <Col>
          <Card>
            <CardBody>
              <h3 className="text-warning"><i className="fas fa-comment-dots text-warning" /> What do people
                   talk about?</h3>
              <p className="info-text"><i className="fas fa-info-circle" /> The graph presents the words that have been used 
                  most frequently by Twitter users in their tweets about the war. The larger the 
                  size of a word, the most frequently it appears in the tweets. Hovering over a 
                  word shows the number of times this word appeared in our data.</p>
              <WordCloud data={dataWords} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      </div>
      )}
    </>
  )
}

export default Clouds;