/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, } from 'chart.js';

import { Line } from 'react-chartjs-2';
import { TwitterTweetEmbed } from "react-twitter-embed";
import Masonry from 'react-masonry-css';

import Loader from "../components/UI/Loader";
import MiniLoader from "../components/UI/MiniLoader";
import {roundToTwo} from "../variables/variables";

const appHost = process.env.REACT_APP_HOST;

// masonry breakpoints setting
const breakpointColumnsObj = {
  default: 2,
  680: 1,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// options for line charts
const options = {
  responsive: true,
  pointRadius: 5,
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: false,
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
}


function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [loadingTweets, setLoadingTweets] = useState(true);
  const [data, setData] = useState([]);
  const [dailyTweets, setDailyTweets] = useState([]);
  const [dailyTags, setDailyTags] = useState([]);
  const [dailyUsers, setDailyUsers] = useState([]);
  const [rateTweets, setRateTweets] = useState(null);
  const [rateTags, setRateTags] = useState(null);
  const [rateUsers, setRateUsers] = useState(null);
  const [mostFavoriteTweet, setMostFavoriteTweet] = useState(null);
  const [mostRetweetedTweet, setMostRetweetedTweet] = useState(null);
  const [topUrl, setTopUrl] = useState(null);
  const [topUser, setTopUser] = useState(null);

  // const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const FETCH_URL = `${appHost}/get_homepage_stats/`;
      try {
        const {data: response} = await axios.get(FETCH_URL);
        // const {data: response2}  = await axios.get('http://localhost:3000/json-files/test.json');
        setData(response);
        setDailyTweets(response.latest_tweets);
        setDailyTags(response.latest_tags);
        setDailyUsers(response.latest_users);
        setRateTweets(response.rate_tweets);
        setRateTags(response.rate_tags);
        setRateUsers(response.rate_users);
        setMostFavoriteTweet(response.most_favorite);
        setMostRetweetedTweet(response.most_retweeted);
        setTopUrl(response.top_url);
        setTopUser(response.most_active_user);
        // setMetaData(response2);
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    }
    
    fetchData();

  }, []);

  // console.log(metaData);


  // calculate daily differencies to show in cards
  const dailyTweetsDif = roundToTwo(rateTweets);
  const dailyTagsDif = roundToTwo(rateTags);
  const dailyUsersDif = roundToTwo(rateUsers);

  // variables for 1st chart at cards
  const labels1 = Object.keys(dailyTweets);

  const dataLine1 = {
    labels: labels1,
    datasets: [
      {
        label: 'Tweets per Day',
        data: Object.values(dailyTweets),
        borderColor: 'rgba(81, 203, 206, 1)',
        backgroundColor: 'rgba(81, 203, 206, 0.5)',
      },
    ],
  };

  // variables for 2nd chart at cards
  const labels2 = Object.keys(dailyTags);

  const dataLine2 = {
    labels: labels2,
    datasets: [
      {
        label: 'Hashtags per Day',
        data: Object.values(dailyTags),
        borderColor: 'rgba(251, 198, 88, 1)',
        backgroundColor: 'rgba(251, 198, 88, 0.5)',
      },
    ],
  };

  // variables for 3rd chart at cards
  const labels3 = Object.keys(dailyUsers);

  const dataLine3 = {
    labels: labels3,
    datasets: [
      {
        label: 'Users per Day',
        data: Object.values(dailyUsers),
        borderColor: 'rgba(81, 203, 206, 1)',
        backgroundColor: 'rgba(81, 203, 206, 0.5)',
      },
    ],
  };

  const FavTweetsLoaded = () => {
    setLoadingTweets(false);
    return;
  } 


  return (
    <>
    {loading && ReactDOM.createPortal(
    <Loader />
    ,document.getElementById("overlays"))}
    {!loading && (
      <div className="content">
        <Row className="py-3">
          <Col>
          <Card>
            <CardBody>
              <p>The Ukraine Data Observatory (by <a className="alert-link" href="https://datalab.csd.auth.gr/" rel="noreferrer" target="_blank">Datalab AUTh</a>) is a platform for analyzing 
                and visualizing data related to the war between Ukraine and Russia that broke 
                out on February 24, 2022. It collects tweets from the social network of Twitter
                 and aims to inform the international community about the related trends, 
                 discussed topics, popular tweets, and most active users.</p>
                 <p>Data were collected from March 2, 2022 until March 7, 2023.</p>
                 <p>The top trends and analyzed data for the last day of analysis are shown below. For a more detailed analysis and trends
                   about the entire time period, visit the <a href="/wordclouds">Wordclouds</a>, <a href="/top-favorites">Top Favorites</a> & <a href="/top-retweeted">Top Retweeted</a> tweets, <a href="/top-urls">Top URLs</a> & <a href="/top-users">Top Users</a> pages.
                 </p>
            </CardBody>
          </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6} xl={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={3}>
                    <div className="icon-big text-center">
                      <i className="fas fa-calendar-alt text-warning" />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="numbers">
                      <p className="card-category">Data analysis period</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text">From: Wed, 2 Mar 2022<br />To: {data.toDate.slice(0,16)}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
                <hr className="pt-2" />
                <Row>
                  <Col xs={3}>
                    <div className="icon-big text-center">
                    <i className="fa-brands fa-twitter text-warning" />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="numbers">
                      <p className="card-category">Total number of tweets analyzed</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br />{data.total_tweets}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={6} xl={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={3}>
                    <div className="icon-big text-center">
                    <i className="fa-brands fa-twitter text-primary" />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="numbers">
                      <p className="card-category">Number of tweets analyzed on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br />{Object.values(dailyTweets)[6]}  ({dailyTweetsDif}% {dailyTweetsDif > 0 ? <i className="fa-solid fa-arrow-up" /> : <i className="fa-solid fa-arrow-down" />})</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="px-3">
                  <Line options={options} data={dataLine1} height={80} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    <p className="card-category">Average number of tweets analyzed per day: {data.tweets_per_day}</p>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={6} xl={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={3}>
                    <div className="icon-big text-center">
                    <i className="fa-solid fa-hashtag text-warning" />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="numbers">
                      <p className="card-category">Number of hashtags analyzed on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br />{Object.values(dailyTags)[6]}  ({dailyTagsDif}% {dailyTagsDif > 0 ? <i className="fa-solid fa-arrow-up" /> : <i className="fa-solid fa-arrow-down" />})</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="px-3">
                  <Line options={options} data={dataLine2} height={80} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    <p className="card-category">Average number of hashtags analyzed per day: {data.tags_per_day}</p>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={6} xl={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={3}>
                    <div className="icon-big text-center">
                    <i className="fas fa-users text-primary" />
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="numbers">
                      <p className="card-category">Number of users analyzed on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br />{Object.values(dailyUsers)[6]}  ({dailyUsersDif}% {dailyUsersDif > 0 ? <i className="fa-solid fa-arrow-up" /> : <i className="fa-solid fa-arrow-down" />})</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="px-3">
                  <Line options={options} data={dataLine3} height={80} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="text-center">
                    <p className="card-category">Average number of users analyzed per day: {data.users_per_day}</p>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={12} xl={9}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col>
                    <Row>
                      <Col xs={3} sm={2} md={2} lg={1}>
                        <div className="icon-big text-center">
                        <i className="fas fa-heart text-warning" />
                        </div>
                      </Col>
                      <Col xs={9} sm={10} md={10} lg={11}>
                        <div className="numbers">
                          <p className="card-category">Most favorite & most retweeted Tweets on {data.toDate.slice(0,16)}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {loadingTweets && (
                          <Col className="px-3 py-3">
                            <MiniLoader />
                          </Col>
                        )}
                        <Masonry
                          breakpointCols={breakpointColumnsObj}
                          className="masonry-grid"
                          columnClassName="masonry-grid_col"
                        >
                          <TwitterTweetEmbed
                            key={Object.keys(mostFavoriteTweet)[0]}
                            onLoad={FavTweetsLoaded}
                            tweetId={Object.keys(mostFavoriteTweet)[0]}
                          />
                          <TwitterTweetEmbed
                            key={Object.keys(mostRetweetedTweet)[0]}
                            onLoad={FavTweetsLoaded}
                            tweetId={Object.keys(mostRetweetedTweet)[0]}
                          />
                        </Masonry>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={12} lg={12} xl={3}>
            <Card className="card-stats pb-3">
              <CardBody>
                <Row>
                  <Col xs={3} sm={2} md={2} lg={2} xl={3}>
                    <div className="icon-big text-center">
                    <i className="fas fa-link text-primary" />
                    </div>
                  </Col>
                  <Col xs={9} sm={10} md={10} lg={10} xl={9}>
                    <div className="numbers">
                      <p className="card-category">Most frequently posted URL on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br /><a href={topUrl.Link} target="_blank" rel="noreferrer">{topUrl.Link}</a><br />({topUrl.Count} tweets)</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={12} sm={6} md={4} lg={4} xl={12}>
                    <div>
                      <br />
                        <img src={metaData.image.url} alt-text={metaData.title} />
                      <p />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={8} lg={8} xl={12}>
                    <div className="url-card">
                      <h4><a href={metaData.url} target="_blank" rel="noreferrer">{metaData.title}</a></h4>
                      <p className="card-category">{(metaData.description).split(" ").splice(0,25).join(" ")}... <em>({topUrl.Count} tweets)</em></p>
                      <a href={metaData.url} target="_blank" rel="noreferrer" className="text-right"><img width="50px" src={metaData.logo.url} alt-text={metaData.publisher} className="float-right" /></a>
                    </div>
                  </Col>
                </Row> */}
                <hr />
                <Row>
                  <Col xs={3} sm={2} md={2} lg={2} xl={3}>
                    <div className="icon-big text-center">
                    <i className="fa-solid fa-person-rays text-primary" />
                    </div>
                  </Col>
                  <Col xs={9} sm={10} md={10} lg={10} xl={9}>
                    <div className="numbers">
                      <p className="card-category">Most influencing user on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br /><a href={`https://twitter.com/${data.most_influencer}`} rel="noreferrer" target="_blank">{data.most_influencer}</a></CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={3} sm={2} md={2} lg={2} xl={3}>
                    <div className="icon-big text-center">
                    <i className="fa-solid fa-person-running text-primary" />
                    </div>
                  </Col>
                  <Col xs={9} sm={10} md={10} lg={10} xl={9}>
                    <div className="numbers">
                      <p className="card-category">Most active user on {data.toDate.slice(0,16)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="numbers">
                      <CardTitle tag="p" className="card-data-text"><br /><a href={`https://twitter.com/${Object.keys(topUser)}`} rel="noreferrer" target="_blank">{Object.keys(topUser)}</a></CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )}
    </>
  );
}

export default Dashboard;
