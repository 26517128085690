import React from 'react';
import { Spinner } from "reactstrap";

const MiniLoader = () => {
    return (
        <>
          <Spinner color="warning" type="grow"></Spinner>
        </>
    )
}

export default MiniLoader;