import React from 'react';
import './Switch.css';

import {
  Input,
  Label
} from "reactstrap";

const Switch = ({ isOn, handleToggle }) => {
  return (
    <>
      <Input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <Label
        style={{ background: isOn && '#06D6A0' }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </Label>
    </>
  );
};

export default Switch;