import React, { useState } from "react";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";

import Retweeted from "../components/dateRenderedComp/Retweeted";
import {StartingDateCollectingData, defaultStartingDate, defaultEndingDate, defaultEndingDateToShowOnLoad, incrementDate} from "../variables/variables";
import Switch from "../components/UI/Switch";

const TopRetweeted = () => {
  const [startDate, setStartDate] = useState(defaultStartingDate);
  const [endDate, setEndDate] = useState(defaultEndingDate);
  const [value, setValue] = useState(true);

  const handleStartDateSelection = (e) => {
    e.preventDefault();
    setStartDate(e.target.value);
  }

  const handleEndDateSelection = (e) => {
    e.preventDefault();
    setEndDate(incrementDate(e.target.value,1));
  }


  return (
    <>
      <div className="content">
      <Row className="py-1">
        <Col>
          <Card>
            <CardBody>
              <p>Select dates to filter data and results only from tweets within this period
                 (data collection period started on 2 March 2022).</p>
              <Form>
                <Row form>
                  <Col xs={4}>
                    <FormGroup inline>
                      <Label for="selectStartDate">
                        <b>From:</b>
                      </Label>
                      <Input
                        id="selectStartDate"
                        name="start-date"
                        placeholder="date placeholder"
                        type="date"
                        min={StartingDateCollectingData}
                        max={defaultEndingDateToShowOnLoad}
                        onChange={handleStartDateSelection}
                        defaultValue={defaultStartingDate}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup inline>
                      <Label for="selectEndDate">
                        <b>To:</b>
                      </Label>
                      <Input
                        id="selectEndDate"
                        name="end-date"
                        placeholder="date placeholder"
                        type="date"
                        min={StartingDateCollectingData}
                        max={defaultEndingDateToShowOnLoad}
                        onChange={handleEndDateSelection}
                        defaultValue={defaultEndingDateToShowOnLoad}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col xs={2} sm={2} md={1} className="pl-3 pr-1">
                    <FormGroup check>
                      <Switch isOn={value} handleToggle={() => setValue(!value)} />
                    </FormGroup>
                  </Col>
                  <Col xs={10} sm={10} md={11} className="pl-sm-0 pl-md-3 pl-lg-3 pl-xl-0">
                    <p>Show most recently re-tweeted tweets, filtering those posted earlier than 3 days before the chosen starting date.</p>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
        <Retweeted startDate={startDate} endDate={endDate} choice={value} />
      </div>
    </>
  )
}

export default TopRetweeted;