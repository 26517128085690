import React from "react";

import {
  Table,
} from "reactstrap";

const InfluencersTable = ({mostInfluencers}) => {

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              User account name
            </th>
            {/* <th>
              No of tweets
            </th> */}
          </tr>
        </thead>
        <tbody>
          {mostInfluencers.map(item => (
            <tr key={mostInfluencers.indexOf(item)}>
              <td>{mostInfluencers.indexOf(item) + 1}</td>
              <td><a href={`https://twitter.com/${item}`} rel="noreferrer" target="_blank">{item}</a></td>
              {/* <td>{Object.values(item)}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const ActiveUsersTable = ({mostActiveUsers}) => {

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              User account name
            </th>
            {/* <th>
              No of tweets
            </th> */}
          </tr>
        </thead>
        <tbody>
          {mostActiveUsers.map(item => (
            <tr key={mostActiveUsers.indexOf(item)}>
              <td>{mostActiveUsers.indexOf(item) + 1}</td>
              <td><a href={`https://twitter.com/${Object.keys(item)}`} rel="noreferrer" target="_blank">{Object.keys(item)}</a></td>
              {/* <td>{Object.values(item)}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const VisitedUrlsTable = ({mostVisitedUrls}) => {

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              Url
            </th>
            <th>
              Number of Tweets
            </th>
          </tr>
        </thead>
        <tbody>
          {mostVisitedUrls.map(item => (
              <tr key={mostVisitedUrls.indexOf(item)}>
                <td>{mostVisitedUrls.indexOf(item) + 1}</td>
                <td><a href={Object.values(item)[1]} target="_blank" rel="noreferrer">{Object.values(item)[1]}</a></td>
                <td className="text-right">{Object.values(item)[0]}</td>
              </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export  { ActiveUsersTable, VisitedUrlsTable, InfluencersTable };