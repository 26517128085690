import React from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

const About = () => {

  return (
    <>
      <div className="content">
        <Row>
          <Col>
          <Card>
            <CardBody>
            <h3 className="text-warning"><i className="fa-solid fa-circle-info" /> About</h3>
            <p>The Ukraine Data Observatory (by Datalab AUTh) is a platform for analyzing and 
              visualizing data related to the war between Ukraine and Russia that broke out on 
              February 24, 2022. It collects tweets from the social network of Twitter, and aims 
              to inform the international community about the related trends, discussed topics, and 
              most active users. </p>
            <p>The Ukraine Data Observatory was born by scientific curiosity and eagerness to
              examine and understand -from a data science perspective- issues related to the 
              Ukraine-Russia war. In addition to informing users about the statistics that emerge
              from the analysis of the tweets of the social network Twitter, our intention is to 
              reveal and present socio-economic topics that emerged from the war and concern the 
              world community. This initiative is launched and supported by members of the Data 
              and Web Science Lab (DATALAB), an active research group engaged in ICT research and
              innovation on data science and multi scope analytics under the Department of
              Informatics, Aristotle University of Thessaloniki, Greece. The platform examines 
              and analyzes global data, guided by the social feeling and sensitivity required
              to handle data resulting from such unpleasant events.</p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h3 className="text-warning"><i className="fa-solid fa-user-group" /> The Team</h3>
              <p>The Ukraine Data Observatory is developed by the Data and Web Science Lab 
              (Datalab) of the Department of Informatics, Aristotle University of Thessaloniki,
              Greece. Datalab is an active research group engaged in ICT research and innovation
              on data science and social media, and has many years of experience in collecting
              and analyzing data from social networks. Learn more at the Datalab’s website
              https://datalab.csd.auth.gr.</p>
              <p>The team that contributed to the development of the Ukraine Data Observatory is:</p>
              <ul>
                <li>George Dialektakis</li>
                <li>Ilias Dimitriadis</li>
                <li>Dimitrios Panteleimon Giakatos</li>
                <li>Stelios Karamanidis</li>
                <li>Anastasios Meletlidis</li>
                <li>Eva Paraschou</li>
                <li>Christina Karagianni</li>
                <li>Vasileios Psomiadis</li>
                <li>Pavlos Sermpezis</li>
                <li>George Vlahavas</li>
                <li>Sofia Yfantidou</li>
                <li>Athena Vakali (Datalab director)</li>
              </ul>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h3 className="text-warning"><i className="fa-solid fa-database" /> Data sources & Methodology</h3>
              <p><strong>Twitter</strong>. We collect tweets from Twitter users about the war that broke out between
                Russia and Ukraine. The collection is done using the official Twitter API and
                collecting all the tweets  that contain the following hashtags: #Ουκρανια, 
                #ukraine, #Ukraine, #Russia, #UkraineRussiaWar, #RussiaUkraineWar, #UkraineWar,
                #StandWithUkraine,  #UkraineUnderAttack, #Zelensky, #Putin, #RussiaUkraine, 
                #SlavaUkraini, #UkraineInvasion, #UkraineUnderAttack, #Kyiv. </p>
                <p>Data were collected from March 2, 2022 until March 7, 2023.</p>
            </CardBody>
          </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h3 className="text-warning"><i className="fa-solid fa-paper-plane" /> Contact</h3>
                <p>If you encounter any issue or have a suggestion to improve or add new content, 
                please contact us at <a href="mailto:info-datalab@csd.auth.gr">info-datalab@csd.auth.gr</a></p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h3 className="text-warning"><i className="fa-solid fa-share-nodes" /> Follow us</h3>
                <p><a href="https://twitter.com/datalab_auth" rel="noreferrer" target="_blank"><i className="fa-brands fa-twitter fa-2xl pr-3" /></a>  <a href="https://www.facebook.com/datalab.auth" rel="noreferrer" target="_blank"><i className="fa-brands fa-facebook-f fa-2xl px-3" /></a>  <a href="https://www.linkedin.com/company/datalab-auth" rel="noreferrer" target="_blank"><i className="fa-brands fa-linkedin-in fa-2xl px-3" /></a></p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default About;

