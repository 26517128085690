/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation } from "react-router-dom";

import {
  Navbar,
  Container,
  NavbarBrand,
} from "reactstrap";

import logoDatalab from "../../assets/img/datalab_logo_icon.png"

function Header(props) {
  const sidebarToggle = React.useRef();
  const location = useLocation();

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color="dark"
      expand="lg"
      className="navbar-absolute fixed-top navbar-transparent pb-lg-3"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          {/* <NavbarBrand href="/">{getBrand()}</NavbarBrand> */}
          <NavbarBrand href="/">The Ukraine Data Observatory</NavbarBrand>
        </div>
        <img src={logoDatalab} alt="Datalab logo" height="40px"/>
      </Container>
    </Navbar>
  );
}

export default Header;
