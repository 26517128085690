import React from 'react';
import { Spinner } from "reactstrap";
import "./Loader.css"

const Loader = () => {
    return (
        <div className="backdrop">
            <Spinner className="spinner" animation="border" variant="warning" role="status">
                {/* <span className="visually-hidden">Loading...</span> */}
            </Spinner>
        </div>
    )
}

export default Loader;