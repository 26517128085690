import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { VisitedUrlsTable } from "../../variables/tables";
import Loader from "../UI/Loader";

const appHost = process.env.REACT_APP_HOST;

const UrlsComp = ({startDate, endDate}) => {
  const [loading, setLoading] = useState(true);
  const [mostVisitedUrls, setMostVisitedUrls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const FETCH_URL = `${appHost}/get_specific_results/urls/${startDate}/${endDate}/`;
      try {
        const {data: response} = await axios.get(FETCH_URL);
        setMostVisitedUrls(response.urls.slice(0,10));
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    }
    
    fetchData();

  }, [startDate, endDate]);

  return (
    <>
      {loading && ReactDOM.createPortal(
      <Loader />
      ,document.getElementById("overlays"))}
      {!loading && (
        <div>
          <Row className="py-3">
            <Col xs={12} id="top-urls">
              <Card>
                <CardBody>
                  <h3 className="text-warning"><i className="fas fa-link text-warning" /> What URLs are 
                  most frequently posted?</h3>
                  <p className="info-text"><i className="fas fa-info-circle" /> The table below shows the top URLs related to 
                  discussions about the war that Twitter users posted the most times. Clicking on 
                  a URL redirects to the corresponding content.</p>
                  <VisitedUrlsTable mostVisitedUrls={mostVisitedUrls} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default UrlsComp;