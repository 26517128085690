import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { ReTweets, ReTweetsRecent } from "../../variables/tweets";
import Loader from "../../components/UI/Loader";

const appHost = process.env.REACT_APP_HOST;

const Retweeted = ({startDate, endDate, choice}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataRecent, setDataRecent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const FETCH_URL = `${appHost}/get_specific_results/retweets/${startDate}/${endDate}/`;
      const FETCH_URL2 = `${appHost}/get_recent_results/retweets/${startDate}/${endDate}/`;
      try {
        const {data: response} = await axios.get(FETCH_URL);
        const {data: response2} = await axios.get(FETCH_URL2);
        setData(response);
        setDataRecent(response2);
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    }
    
    fetchData();

  }, [startDate, endDate]);


  return (
    <>
      {loading && ReactDOM.createPortal(
      <Loader />
      ,document.getElementById("overlays"))}
      {!loading && (
        <div>
          {choice ?
            <Row className="py-3" id="liked">
              <Col>
                <Card>
                  <CardBody>
                    <h3 className="text-warning"><i className="fas fa-retweet" /> Most Recently Retweeted Tweets</h3>
                    <p className="info-text"><i className="fas fa-info-circle" /> Below are the 10 
                    most recently re-tweeted tweets about the war. Clicking on a tweet redirects to the tweet page in Twitter.</p>
                    <Row>
                      <Col>
                        <ReTweetsRecent data={dataRecent}/>
                      </Col>   
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> :
            <Row className="py-3" id="retweeted">
              <Col>
                <Card>
                  <CardBody>
                    <h3 className="text-warning"><i className="fas fa-retweet text-warning" /> Most Retweeted Tweets</h3>
                    <p className="info-text"><i className="fas fa-info-circle" /> Below are the 10 most
                     re-tweeted tweets about the war. Clicking on a tweet redirects to the tweet page in Twitter. </p>
                      <Row>
                        <Col>
                          <ReTweets data={data}/>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </div>
      )}
    </>
  )
}

export default Retweeted;