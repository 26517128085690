import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { ActiveUsersTable, InfluencersTable } from "../../variables/tables";
import Loader from "../UI/Loader";

const appHost = process.env.REACT_APP_HOST;

const UsersComp = ({startDate, endDate, checkend, checkstart, limit}) => {
  const [loading, setLoading] = useState(true);
  const [mostActiveUsers, setMostActiveUsers] = useState([]);
  const [mostInfluencers, setMostInfluencers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const FETCH_URL = `${appHost}/get_specific_results/users/${startDate}/${endDate}/`;
      const FETCH_URL2 = `${appHost}/get_influencer_results/${startDate}/${endDate}/`;
    
      try {
        const {data: response} = await axios.get(FETCH_URL);
        const {data: response2}  = await axios.get(FETCH_URL2);
        setMostActiveUsers(response.users.slice(0,10));
        setMostInfluencers(response2.influencers.slice(0,10));
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    }
    
    fetchData();

  }, [startDate, endDate]);

  return (
    <>
      {loading && ReactDOM.createPortal(
      <Loader />
      ,document.getElementById("overlays"))}
      {!loading && (
        <div>
          <Row className="py-3">
            <Col xs={12} sm={12} md={6} id="top-users">
              <Card>
                <CardBody>
                  <h3 className="text-warning"><i className="fa-solid fa-person-rays" /> Who is most 
                  influencing?</h3>
                  <p className="info-text"><i className="fas fa-info-circle" /> The table below shows the Twitter users 
                  whose posts are the more influencial. Clicking on a user name 
                  redirects to their Twitter profile page. </p>
                  {checkstart < limit && checkend < limit 
                  ?
                    <p>There are no available data for the selected dates. Please select an ending time period date after March 18.</p>
                   : 
                      <InfluencersTable mostInfluencers={mostInfluencers}/>
                    }
                  {/* <InfluencersTable mostInfluencers={mostInfluencers}/> */}
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} id="top-users">
              <Card>
                <CardBody>
                  <h3 className="text-warning"><i className="fa-solid fa-person-running" /> Who is most 
                  actively tweet-ing?</h3>
                  <p className="info-text"><i className="fas fa-info-circle" /> The table below shows the most active Twitter users 
                  that posted the largest number of tweets about the war. Clicking on a user name 
                  redirects to their Twitter profile page. </p>
                  <ActiveUsersTable mostActiveUsers={mostActiveUsers}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default UsersComp;